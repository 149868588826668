export enum SubscriptionDomain {
  Purchasing = '',
  Active = 'active',
  AutoRenewal = 'auto-renewal',
  History = 'history',
  promoCode = 'promo-code',
}

export enum SubscriptionsIds {
  Premium = 27,
  Standart = 24,
}
